.Button {
    display: inline-block;
    border-radius: 4px;
    margin: 20px 20px 20px 0px;
    color: #fdfdfd;
    transition: transform 0.2s;
    padding: 8px;
    width: 180px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border: none;
    overflow: visible;
    
}


.Button:focus {
    outline: none;
}

.ButtonEnabled {
    background-color: #5eadc5;
}

.ButtonEnabled:hover {
    transform: translateY(4px);
}

.ButtonDisabled {
    cursor: default;
}

.Button > label {
    display: flex;
    align-items: center;    
}

.labelEnabled {
    cursor: pointer;
}

.labelDisabled {
    cursor: default;
}

.Button img {
    width: 40px;
    height: 40px;
}

.Button span {
    margin-left: 10px;
}

.Button input {
    display: none;
}