.container {
max-width: 600px;
}

.menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.menu select {
    padding: 16px;
    font-size: 18px;
    border-radius: 4px;
    margin-right: 20px;
}

.imageItem {
    background-color: #5eadc5;
    color: #227b94;
    height: 60px;
    margin: 8px 0px;
    border-radius: 4px;
    box-shadow: 1px 3px 10px;
    margin: 10px 0;
    display: flex;
    align-items: center;

}

.imageItem > div {
    margin: 10px;
}