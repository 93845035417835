.container {
    padding: 40px 60px;
}

.header {
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 40px;
    color: #3C454C;
}

.item {
    padding: 20px 40px;
    margin-bottom: 30px;
    background-color: #5eadc5;
    color: #227b94;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 240px;
    min-height: 90px;
}

.name {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.deleteCategory {
    margin-top: 20px;
    cursor: pointer;
    transition: transform 0.2s;
}

.deleteCategory:hover {
    transform: translateY(4px);
}

.deleteCategory img {
    width: 30px;
    height: 30px;
    display: block;
}

.deleteConfirm {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.deleteConfirmButton {
    cursor: pointer;
    margin-top: 4px;
    text-decoration: none;

}

.deleteConfirmButton:hover {
    color: white;
    text-decoration: underline;
}

.addCategory {
    background-color: #227b94;
    border-radius: 4px;
    padding: 20px;
    color: #5eadc5;
}

.addCategoryHeader {
    text-align: center;
    font-size: 24px;
    text-transform: uppercase;
}

.addCategorySubmit {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
}

.submitButton {
    transition: transform 0.2s;
    cursor: pointer;
}

.submitButton:hover {
    transform: translateY(4px);
}

.submitButton img {
    width: 30px;
    height: 30px;
    display: block;

}

.addCategory input {
    padding: 6px;
}