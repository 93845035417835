body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  background-color: #f4f4f4;
  color: #403d49;
}


.loadingScreen {
  width: 100%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #5eadc5;
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 500ms ease-in-out;
}


.sk_folding_cube {
  margin: 20px auto;
  width: 60px;
  height: 60px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}

.sk_folding_cube .sk_cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
          transform: scale(1.1); 
}
.sk_folding_cube .sk_cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
          animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}
.sk_folding_cube .sk_cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);
}
.sk_folding_cube .sk_cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);
}
.sk_folding_cube .sk_cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);
}
.sk_folding_cube .sk_cube2:before {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sk_folding_cube .sk_cube3:before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s; 
}
.sk_folding_cube .sk_cube4:before {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  } 
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  }
}

.Header_header__3eBFZ {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;
    
}

.Header_logo__1lE_2 {
    width: 160px;
    min-width: 160px;
    height: 162px;
    margin: 40px auto;
    
}



/* Smaller Screens */
@media only screen  and (min-width : 768px) {
    .Header_header__3eBFZ {
        width: 68%;
    }

    .Header_logo__1lE_2 {
        width: 100px;
        height: 101px;
        min-width: 100px;
        margin-top: 0;
    }
}

/* Desktops & Laptops */
@media only screen  and (min-width : 1224px) {

    .Header_logo__1lE_2 {
        width: 200px;
        height: 202px;
        min-width: 200px;
    }
}

.NavLinks_linkContainer__gwIms {
    width: 100%;
    margin-top: 26px;
    display: none;
}

.NavLinks_link__Yxsfj {
    color: #5eadc5;
    font-family: lato, sans-serif;
    font-weight: 200;
    font-style: italic;
    font-size: 2.2vw;
    text-transform: uppercase;
    text-decoration: none;
}

.NavLinks_mobileLink__VfSyy {
  color: #403d49;
  text-decoration: none;

}

.NavLinks_link__Yxsfj:hover {
    color: #227b94;
}

.NavLinks_active__1AXm7 {
    text-decoration: underline;
    -webkit-text-decoration-color: #403d49;
            text-decoration-color: #403d49;
}

/* Style The Dropdown Button */
.NavLinks_dropbtn__2WOed {
    color: #5eadc5;
    font-family: lato, sans-serif;
    font-weight: 200;
    font-style: italic;
    font-size: 2.2vw;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }

.NavLinks_dropbtn__2WOed img {
    width: 20px;
    height: auto;
    margin-left: 10px;
}
  
  /* The container <div> - needed to position the dropdown content */
  .NavLinks_dropdown__317m5 {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .NavLinks_dropdown_content__2b8Rn {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .NavLinks_dropdown_content__2b8Rn a {
    padding: 12px 16px;
    display: block;
    font-size: 1.2vw
  }
  
  /* Change color of dropdown links on hover */
  .NavLinks_dropdown_content__2b8Rn a:hover {background-color: #f1f1f1}
  
  /* Show the dropdown menu on hover */
  .NavLinks_dropdown__317m5:hover .NavLinks_dropdown_content__2b8Rn {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .NavLinks_dropdown__317m5:hover .NavLinks_dropbtn__2WOed {
    color: #227b94;
  }

  /* MOBILE MENU*/

  .NavLinks_mobileLinkContainer__3wUoP {
    background-color: #5eadc5;
  }
  
  .NavLinks_mobileMenuButton__1YFvi {
    background-color: #5eadc5;
    cursor: pointer;
    width: 100%;
    height: 60px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: baseline;
            justify-content: baseline;
    -webkit-align-items: center;
            align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    border-bottom: 2px solid #227b94;
  }
  
.NavLinks_mobileMenuButton__1YFvi img {
  height: 40px;
  width: 40px;
  margin-left: 10px;
}

.NavLinks_mobileMenuContent__2lbjf {
  position: fixed;
  top: 60px;
  left: 0;
  height: auto;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  background-color: #5eadc5;
  -webkit-flex-direction: column;
          flex-direction: column;
}


.NavLinks_mobileMenuContent__2lbjf div {
  list-style-type: none;
}


.NavLinks_mobileMenuContent__2lbjf a {
  padding: 10px 0px 10px 10px;
  font-size: 20px;
  text-transform: uppercase;
  color: #227b94;
  font-weight: 400;
  display: block;
}

/* Smaller Screens */
@media only screen  and (min-width : 768px) {
    .NavLinks_linkContainer__gwIms {
        display: -webkit-flex;
        display: flex;
    }

    .NavLinks_mobileLinkContainer__3wUoP {
        display: none;
    }
}

/* Desktops & Laptops */
@media only screen  and (min-width : 1224px) {

    .NavLinks_linkContainer__gwIms {
        margin-top: 74px;
    }

    .NavLinks_link__Yxsfj,.NavLinks_dropbtn__2WOed {
        font-size: 1.8vw;
    }
}
.Footer_footer__1JpB5 {
    background-color: #5eadc5;
    height: 220px;
    margin-top: 40px;
    color: #227b94;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
}

.Footer_email__6IQCU {
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    padding: 0px 4px 8px 4px;
    border-bottom: 2px solid #227b94;

}

.Footer_rights__2AlcG {
margin-top: 6px;
font-size: 18px;
text-transform: uppercase;
}

.Footer_credits__uh6K7 {
position: absolute;
bottom: 22px;
}

.Footer_credits__uh6K7 a {
    color: #403d49 
}

.Footer_footer__1JpB5 > a {
    color: white;
}


.GalleryImages_images__33Kej {

    width: 86%;
    margin: 0 auto;
    -webkit-column-count: 1;
    column-count:         1;
    line-height: 0;

    -webkit-column-gap:   20px;
    -moz-column-gap:      20px;
    grid-column-gap:           20px;
    column-gap:           20px; 
}

.GalleryImages_images__33Kej img { 
    width: 100%;
    height: auto;
    margin-top: 20px;
    box-shadow: 5px 5px 20px;
    /* transition: all 0.6 linear; */
}

@media only screen  and (min-width : 768px) {
    .GalleryImages_images__33Kej {
        -webkit-column-count: 2;
        column-count:         2;
    }
}

/* Desktops & Laptops */
@media only screen  and (min-width : 1224px) {
    .GalleryImages_images__33Kej {
        -webkit-column-count: 3;
        column-count:         3;
    }
}


.GalleryImages_images2__1haei {
    width: 86%;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

}

.GalleryImages_images2__1haei > div {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    

}

.GalleryImages_images2__1haei img { 
    width: 100%;
    height: auto;
    margin-top: 20px;
    box-shadow: 5px 5px 20px;
    cursor: pointer;
}

.GalleryImages_modalImage__3LCGq {
    object-fit: contain
}

.GalleryImages_modalCloseButton__1zL_F {
    cursor: pointer;
}

.GalleryImages_modalImagePortrait__11WvA {
    height: 100%;
    width: auto;
}


.GalleryImages_modalImageLandscape__4GHv5 {
    height: auto;
    width: 100%
}

.GalleryImages_modalContentPortrait__27Y1N {
    height: 90vh;
    width: auto;
    max-width: none;
    padding: 60px;
    box-sizing: border-box;

}

.GalleryImages_modalContentLandscape__2U3xi {
    width: 60vw;
    height: auto;
    max-width: none;
    padding: 60px;
    box-sizing: border-box;
}

.GalleryImages_modalContentBigLandscape__3xeCW {
    width: 92vw;
    height: auto;
    max-width: none;
    padding: 60px;
    box-sizing: border-box;
}




.GalleryImages_modalContent__1Lz4p {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 60px;
    box-sizing: border-box;
    
}

.GalleryImages_modalCloseButton__1zL_F:focus {
    outline:none;
}

@media only screen  and (min-width : 1224px) {
    .GalleryImages_images2__1haei {
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }

    .GalleryImages_images2__1haei > div {
        width: 32%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        
    
    }
}

.Admin_content__3wl4m {
    width: 86%;
    margin: 0 auto;
    padding-bottom: 60px;
}

.Admin_header__277qA {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.Admin_modalCloseButton__FdXIL {
    cursor: pointer;
}

.Admin_modalContent__1SvOb {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 40px;
}

.Admin_modalCloseButton__FdXIL:focus {
    outline:none;
}

.Admin_loginHeader__18GVz {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}
.Button_Button__vUdFa {
    display: inline-block;
    border-radius: 4px;
    margin: 20px 20px 20px 0px;
    color: #fdfdfd;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    padding: 8px;
    width: 180px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    -webkit-justify-content: center;
            justify-content: center;
    border: none;
    overflow: visible;
    
}


.Button_Button__vUdFa:focus {
    outline: none;
}

.Button_ButtonEnabled__1QSHu {
    background-color: #5eadc5;
}

.Button_ButtonEnabled__1QSHu:hover {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.Button_ButtonDisabled__2QGGJ {
    cursor: default;
}

.Button_Button__vUdFa > label {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;    
}

.Button_labelEnabled__29dKD {
    cursor: pointer;
}

.Button_labelDisabled__woDui {
    cursor: default;
}

.Button_Button__vUdFa img {
    width: 40px;
    height: 40px;
}

.Button_Button__vUdFa span {
    margin-left: 10px;
}

.Button_Button__vUdFa input {
    display: none;
}
.ListImages_listItem__2CfeH {
  background-color: #fdfdfd;
  height: 60px;
  margin: 8px 0px;
  border-radius: 4px;
  box-shadow: 1px 3px 10px;
  margin: 10px 0;
  position: relative;
  transition: all 0.6s ease; }
  .ListImages_listItem__2CfeH label {
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-align: left;
    color: #3C454C;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden; }
    .ListImages_listItem__2CfeH label:before {
      width: 30px;
      height: 10px;
      border-radius: 50%;
      content: '';
      background-color: #5eadc5;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
              transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1; }
    .ListImages_listItem__2CfeH label:after {
      width: 32px;
      height: 32px;
      content: '';
      border: 2px solid #D1D7DC;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 3px 5px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in; }
  .ListImages_listItem__2CfeH input[type="checkbox"]:checked ~ label {
    color: #fff; }
    .ListImages_listItem__2CfeH input[type="checkbox"]:checked ~ label:before {
      -webkit-transform: translate(-50%, -50%) scale3d(56, 56, 1);
              transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1; }
    .ListImages_listItem__2CfeH input[type="checkbox"]:checked ~ label:after {
      background-color: #54E0C7;
      border-color: #54E0C7; }
  .ListImages_listItem__2CfeH input[type="checkbox"] {
    width: 32px;
    height: 32px;
    -webkit-order: 1;
            order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden; }

.ListImages_listItemThumbnail__2nAxs {
  height: 50px;
  width: 80px;
  padding: 5px;
  border-radius: 7px; }

.ListImages_categories__3Sxl1 {
  margin-left: 20px; }

.UploadingImagesStatusNotification_notificationContainer__3hckL {
    background-color: #fdfdfd;
    height: auto;
    margin: 8px 0px;
    border-radius: 4px;
    box-shadow: 1px 3px 10px;
    margin: 10px 0 40px 0;
    position: relative;
    transition: all 0.6s ease;
    padding: 20px;
    display: grid;
    grid-template-columns: auto auto 1fr;
    font-size: 20px;
    -webkit-align-items: center;
            align-items: center;
    transition: all 600ms ease-in;
}

.UploadingImagesStatusNotification_notificationItemFileName__3qSU8 {
  margin: 10px 0 10px 0; 
}

.UploadingImagesStatusNotification_notificationItemStatus__3QEwz {
  justify-items: start;
  margin: 10px 0 10px 20px;
  position: relative;
}

.UploadingImagesStatusNotification_statusIcon__2ldYB {
  transition: all 600ms ease-in;
}

.UploadingImagesStatusNotification_loadingSpinner__Ilqcl {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }
  .UploadingImagesStatusNotification_loadingSpinner__Ilqcl:after {
    content: " ";
    display: block;
    width: 24px;
    height: 24px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #5eadc5;
    border-color: #5eadc5 transparent #5eadc5 transparent;
    -webkit-animation: UploadingImagesStatusNotification_loadingSpinner__Ilqcl 1.2s linear infinite;
            animation: UploadingImagesStatusNotification_loadingSpinner__Ilqcl 1.2s linear infinite;
  }

  .UploadingImagesStatusNotification_successIcon__3p7jG {
    margin-left: 16px;
    width: 24px;
    height: 24px;
    display: inline-block;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/foto-25c4c.appspot.com/o/Assets%2FsuccessIcon.png?alt=media&token=28deb8c0-504e-4792-85d9-5335a494b050');
  }

  .UploadingImagesStatusNotification_errorIcon__VdUNH {
    margin-left: 16px;
    width: 24px;
    height: 24px;
    display: inline-block;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/foto-25c4c.appspot.com/o/Assets%2FerrorIcon.png?alt=media&token=eecfa7da-d42e-4160-87a8-fd47feeb24ad');
  }


  @-webkit-keyframes UploadingImagesStatusNotification_loadingSpinner__Ilqcl {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }


  @keyframes UploadingImagesStatusNotification_loadingSpinner__Ilqcl {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
.CategorySettings_container__1qD5Q {
    padding: 40px 60px;
}

.CategorySettings_header__1PEP3 {
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 40px;
    color: #3C454C;
}

.CategorySettings_item__3V4zc {
    padding: 20px 40px;
    margin-bottom: 30px;
    background-color: #5eadc5;
    color: #227b94;
    border-radius: 4px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    min-width: 240px;
    min-height: 90px;
}

.CategorySettings_name__3Jere {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.CategorySettings_deleteCategory__2AkVc {
    margin-top: 20px;
    cursor: pointer;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
}

.CategorySettings_deleteCategory__2AkVc:hover {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.CategorySettings_deleteCategory__2AkVc img {
    width: 30px;
    height: 30px;
    display: block;
}

.CategorySettings_deleteConfirm__AmWAq {
    margin-top: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.CategorySettings_deleteConfirmButton__-WjIV {
    cursor: pointer;
    margin-top: 4px;
    text-decoration: none;

}

.CategorySettings_deleteConfirmButton__-WjIV:hover {
    color: white;
    text-decoration: underline;
}

.CategorySettings_addCategory__1Bt8n {
    background-color: #227b94;
    border-radius: 4px;
    padding: 20px;
    color: #5eadc5;
}

.CategorySettings_addCategoryHeader__3kpxY {
    text-align: center;
    font-size: 24px;
    text-transform: uppercase;
}

.CategorySettings_addCategorySubmit__1k42J {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    margin-top: 20px;
}

.CategorySettings_submitButton__1IEle {
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    cursor: pointer;
}

.CategorySettings_submitButton__1IEle:hover {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.CategorySettings_submitButton__1IEle img {
    width: 30px;
    height: 30px;
    display: block;

}

.CategorySettings_addCategory__1Bt8n input {
    padding: 6px;
}
.EditCategory_container__6jbac {
max-width: 600px;
}

.EditCategory_menu__N9w1X {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.EditCategory_menu__N9w1X select {
    padding: 16px;
    font-size: 18px;
    border-radius: 4px;
    margin-right: 20px;
}

.EditCategory_imageItem__3fLmx {
    background-color: #5eadc5;
    color: #227b94;
    height: 60px;
    margin: 8px 0px;
    border-radius: 4px;
    box-shadow: 1px 3px 10px;
    margin: 10px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;

}

.EditCategory_imageItem__3fLmx > div {
    margin: 10px;
}
