.images {

    width: 86%;
    margin: 0 auto;

    -moz-column-count:    1;
    -webkit-column-count: 1;
    column-count:         1;
    line-height: 0;

    -webkit-column-gap:   20px;
    -moz-column-gap:      20px;
    column-gap:           20px; 
}

.images img { 
    width: 100%;
    height: auto;
    margin-top: 20px;
    box-shadow: 5px 5px 20px;
    /* transition: all 0.6 linear; */
}

@media only screen  and (min-width : 768px) {
    .images {
        -moz-column-count:    2;
        -webkit-column-count: 2;
        column-count:         2;
    }
}

/* Desktops & Laptops */
@media only screen  and (min-width : 1224px) {
    .images {
        -moz-column-count:    3;
        -webkit-column-count: 3;
        column-count:         3;
    }
}


.images2 {
    width: 86%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.images2 > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    

}

.images2 img { 
    width: 100%;
    height: auto;
    margin-top: 20px;
    box-shadow: 5px 5px 20px;
    cursor: pointer;
}

.modalImage {
    object-fit: contain
}

.modalCloseButton {
    cursor: pointer;
}

.modalImagePortrait {
    height: 100%;
    width: auto;
}


.modalImageLandscape {
    height: auto;
    width: 100%
}

.modalContentPortrait {
    height: 90vh;
    width: auto;
    max-width: none;
    padding: 60px;
    box-sizing: border-box;

}

.modalContentLandscape {
    width: 60vw;
    height: auto;
    max-width: none;
    padding: 60px;
    box-sizing: border-box;
}

.modalContentBigLandscape {
    width: 92vw;
    height: auto;
    max-width: none;
    padding: 60px;
    box-sizing: border-box;
}




.modalContent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
    box-sizing: border-box;
    
}

.modalCloseButton:focus {
    outline:none;
}

@media only screen  and (min-width : 1224px) {
    .images2 {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .images2 > div {
        width: 32%;
        display: flex;
        flex-direction: column;
        
    
    }
}
