.content {
    width: 86%;
    margin: 0 auto;
    padding-bottom: 60px;
}

.header {
    display: flex;
    flex-wrap: wrap;
}

.modalCloseButton {
    cursor: pointer;
}

.modalContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
}

.modalCloseButton:focus {
    outline:none;
}

.loginHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
}