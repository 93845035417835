.linkContainer {
    width: 100%;
    margin-top: 26px;
    display: none;
}

.link {
    color: #5eadc5;
    font-family: lato, sans-serif;
    font-weight: 200;
    font-style: italic;
    font-size: 2.2vw;
    text-transform: uppercase;
    text-decoration: none;
}

.mobileLink {
  color: #403d49;
  text-decoration: none;

}

.link:hover {
    color: #227b94;
}

.active {
    text-decoration: underline;
    text-decoration-color: #403d49;
}

/* Style The Dropdown Button */
.dropbtn {
    color: #5eadc5;
    font-family: lato, sans-serif;
    font-weight: 200;
    font-style: italic;
    font-size: 2.2vw;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

.dropbtn img {
    width: 20px;
    height: auto;
    margin-left: 10px;
}
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown_content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown_content a {
    padding: 12px 16px;
    display: block;
    font-size: 1.2vw
  }
  
  /* Change color of dropdown links on hover */
  .dropdown_content a:hover {background-color: #f1f1f1}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown_content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    color: #227b94;
  }

  /* MOBILE MENU*/

  .mobileLinkContainer {
    background-color: #5eadc5;
  }
  
  .mobileMenuButton {
    background-color: #5eadc5;
    cursor: pointer;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: baseline;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    border-bottom: 2px solid #227b94;
  }
  
.mobileMenuButton img {
  height: 40px;
  width: 40px;
  margin-left: 10px;
}

.mobileMenuContent {
  position: fixed;
  top: 60px;
  left: 0;
  height: auto;
  width: 100%;
  display: flex;
  background-color: #5eadc5;
  flex-direction: column;
}


.mobileMenuContent div {
  list-style-type: none;
}


.mobileMenuContent a {
  padding: 10px 0px 10px 10px;
  font-size: 20px;
  text-transform: uppercase;
  color: #227b94;
  font-weight: 400;
  display: block;
}

/* Smaller Screens */
@media only screen  and (min-width : 768px) {
    .linkContainer {
        display: flex;
    }

    .mobileLinkContainer {
        display: none;
    }
}

/* Desktops & Laptops */
@media only screen  and (min-width : 1224px) {

    .linkContainer {
        margin-top: 74px;
    }

    .link,.dropbtn {
        font-size: 1.8vw;
    }
}